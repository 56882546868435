import React from 'react';

import Header from "../components/header";

import Footer from "../components/footer";




// markup
const NotFoundPage = () => {
  return (
      <div className="bg-[#021002]">
        <header className="flex flex-col">
          <div className="flex text-[#e5d491] border-styles">
            <Header />
          </div>
        </header>
          <div className="relative bg-cover bg-center min-h-[520px]" style={{backgroundImage: `url('/canil-dois-pinheiros-404.png')`}}>
              <div className="absolute inset-0 bg-black bg-opacity-25">
              </div>
              <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 pb-10">
                  <div className="text-white text-lg mr-5 text-center">
                      Nos desculpe, o conteúdo que você procura mudou.
                      <br/>
                      <span className="text-white text-lg mr-5">
                          Encontre seu filhote aqui
                      </span>
                      <br/>
                      <a href="/" className="text-white text-lg mr-5">Início</a>
                      <a href="https://api.whatsapp.com/send?phone=5511984164914"
                         className="text-white text-lg">WhatsApp</a>
                  </div>
              </div>
          </div>
          <Footer></Footer>
      </div>

  )
}

export default NotFoundPage
